import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import Button from 'bank-component-library/ui/atoms/Button';
import { FeatureFlag } from '../../utilities/isFeatureFlagEnabled';
import useStyles from './ControlPanel.styles';
import i18n from '../../strings/i18n';
import { FeatureFlagPanelProps, FeatureFlagValue } from './ControlPanel.typings';
import { getFeatureFlags } from './helpers';

const FeatureFlagPanel = ({ flags, setFlags }: FeatureFlagPanelProps) => {
  const classes = useStyles();

  const enabledFlags: FeatureFlag[] = ['EPREFERENCES_NEW_DESIGN_ENABLED', 'TRANSMIT_ENABLED'];

  const updateFeatureFlag = (flagName: string, value: string) => {
    const newValue = value === 'true' ? 'false' : 'true';
    sessionStorage.setItem(flagName, newValue);
    setFlags({ ...flags, [flagName]: newValue });
  };

  const resetFeatureFlags = () => {
    for (const [key] of Object.entries(flags)) {
      sessionStorage.removeItem(key);
    }
    setFlags(getFeatureFlags());
  };
  return (
    <>
      {Object.entries(flags).map(([key]: [FeatureFlag, FeatureFlagValue]) => {
        return (
          <FormControlLabel
            className={classes.flagLabel}
            key={key}
            control={
              <Switch
                checked={flags[key] === 'true'}
                onChange={() => updateFeatureFlag(key, flags[key])}
                name={key}
              />
            }
            label={key}
            disabled={!enabledFlags.includes(key)}
          />
        );
      })}
      <div className={classes.resetFlagButton}>
        <Button size="large" type="button" onClick={resetFeatureFlags}>
          {i18n({ actions: 'resetAllFeatureFlags' })}
        </Button>
      </div>
    </>
  );
};

export default FeatureFlagPanel;
