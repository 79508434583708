import React, { useState } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import Button from 'bank-component-library/ui/atoms/Button';
import useStyles from './ControlPanel.styles';
import SVGImage from '../svgImage';
import { CloseIconSVG } from '../svg/svg';
import i18n from '../../strings/i18n';
import MockServiceWorkerPanel from './MockServiceWorkerPanel';
import FeatureFlagPanel from './FeatureFlagPanel';
import PanelNavigation from './PanelNavigation';
import { Panel } from './ControlPanel.typings';
import useEffectOnce from '../../utilities/reactHooks';
import { getMswConfig, startMSW } from '../../mocks';
import { FeatureFlags } from '../../utilities/isFeatureFlagEnabled';
import { getFeatureFlags, initializeSessionFlags } from './helpers';

/**
 *  Control Panel for enabling / disabling feature flags, enabling Mock Service Worker,
 *  and other dev functionality down the line.
 */
export default function ControlPanel() {
  const [minimize, setMinimize] = useState(true);
  const [activePanel, setActivePanel] = useState<Panel>('ff');
  const [flags, setFlags] = useState<FeatureFlags>(getFeatureFlags());
  const classes = useStyles();

  const panels: { [K in Panel]: JSX.Element } = {
    ff: <FeatureFlagPanel flags={flags} setFlags={setFlags} />,
    msw: <MockServiceWorkerPanel />,
  };

  const handleClose = () => {
    setMinimize(!minimize);

    // refresh the page to be sure the flag change goes into effect immediately
    window.location.reload();
  };

  useEffectOnce(() => {
    if (getMswConfig().MSW_ENABLED) startMSW();
    initializeSessionFlags(flags);
  });

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
        zIndex: '1000000',
      }}
      className="opacity-25 hover:opacity-100"
    >
      <Button type="button" size="small" onClick={() => setMinimize(!minimize)}>
        {i18n({ actions: 'showControlPanel' })}
      </Button>
      <Drawer anchor="right" open={!minimize} onClose={handleClose}>
        <div style={{ paddingBlock: '2rem', paddingBottom: '6rem', width: '430px' }}>
          <div style={{ position: 'fixed', top: '1rem', right: '1rem' }}>
            <IconButton onClick={handleClose} aria-label="Close">
              <SVGImage
                iconComponent={
                  <CloseIconSVG className={classes.icon} aria-hidden="true" role="img" />
                }
              />
            </IconButton>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1fr, auto)',
              gap: '8px',
            }}
          >
            <PanelNavigation
              tabs={[
                {
                  displayName: 'Feature Flags',
                  panel: 'ff',
                },
                {
                  displayName: 'Mock Service Worker',
                  panel: 'msw',
                },
              ]}
              activePanel={activePanel}
              setActivePanel={setActivePanel}
            />
            {panels[activePanel]}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
