import { mockAccountSummary } from 'TestUtils/mock-account-details';
import { ApplicationStatuses } from '../../containers/newAccountOpening/newAccountOpening.constants.tsx';

export const mockTrackingId = 'dost532-oyev321-sky298';

export const mockProduct = {
  product: {
    id: '306',
    fundsSource: 'Current Income',
    atmCard: false,
    checks: false,
  },
};

export const mockAffinityCompanyCode = '0091887';
export const mockNewApplicant = {
  newCustomerInformation: {
    affinityCompanyCode: mockAffinityCompanyCode,
    dateOfBirth: '1982-10-12',
    emailAddress: 'test@willowtreeapps.com',
    employment: {
      employer: '',
      occupation: 'X000',
    },
    name: {
      first: 'Foo',
      middle: 'Bar',
      last: 'Baz',
    },
    nationality: 'X840',
    noPhoneIndicator: false,
    phoneNumber: '2025550138',
    phoneType: 'Mobile',
    primaryAddress: {
      city: 'Charlottesville',
      line1: '107 5th St SE',
      state: 'XVA',
      unitNumber: '1',
      zipCode: '22902',
    },
    securityAnswer: 'yeet',
    securityQuestion: 'Hospital where you were born',
    taxId: '34',
  },
  termsAccepted: {
    32111: true,
    54321: true,
    37111: true,
    withholding: true,
  },
};

export const mockNewJointApplicant = {
  newCustomerInformation: {
    dateOfBirth: '1982-10-11',
    emailAddress: 'joint@willowtreeapps.com',
    employment: {
      employer: '',
      occupation: 'X000',
    },
    name: {
      first: 'Joint',
      middle: 'Middle',
      last: 'Owner',
    },
    nationality: 'X840',
    noPhoneIndicator: false,
    phoneNumber: '2025550138',
    phoneType: 'Mobile',
    primaryAddress: {
      city: 'Charlottesville',
      line1: '107 5th St SE',
      state: 'XVA',
      unitNumber: '1',
      zipCode: '22902',
    },
    securityAnswer: 'home birth',
    securityQuestion: 'Hospital where you were born',
    taxId: '34',
  },
  termsAccepted: {
    32111: true,
    54321: true,
  },
};

export const mockExistingApplicant = {
  existingCustomerInformation: {
    customerId: 'abc123',
  },
  termsAccepted: {
    32111: true,
    54321: true,
  },
};

export const mockEAOApplication = {
  primaryApplicant: mockExistingApplicant,
  jointApplicants: [mockExistingApplicant, mockNewJointApplicant],
  trackingId: mockTrackingId,
  ...mockProduct,
};

export const mockNAOApplication = {
  primaryApplicant: mockNewApplicant,
  jointApplicants: [mockExistingApplicant, mockNewJointApplicant],
  trackingId: mockTrackingId,
  ...mockProduct,
};

export const mockSubmitApplicationClientResponse = {
  accounts: [mockAccountSummary],
  applicantId: [{ id: 'b7164e83-f986-49fe-a7a5-6f4263687643', dob: '1959-09-22', las4ssn: '2243' }],
  applicationId: '123',
  message: 'Your application was success!',
  primaryCustomer: { affinity: false, customerId: '456', certified: false },
  status: ApplicationStatuses.CREATED,
};
